import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GitHubIcon from "@material-ui/icons/GitHub";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home">
      <div className="about">
        <h2> Hello, My Name is Mohammed</h2>
        <div className="prompt">
          <p className="dev" >A developer passionate about learning and innovation.</p>
          <a href="https://linkedin.com/in/xmohammed-ali-9b793b165" target="_blank"><LinkedInIcon/></a>
          <a href = "mailto: mkarim.ali@outlook.com"> <EmailIcon /></a>
          <a href="https://github.com/Mommeeeh1" target="_blank"><GitHubIcon /></a>
        </div>
      </div>
      <div className="skills">
        <h1> Skills</h1>
        <ol className="list">
          <li className="item">
            <h2> Front-End</h2>
            <span>
              ReactJS, Angular, HTML, CSS, React Native, NPM,
              Ionic, BootStrap, AngularMaterial, ASP.NET Core
            </span>
          </li>
          <li className="item">
            <h2>Back-End</h2>
            <span>
              NodeJS, .NET, ExpressJS, MySQL, MongoDB, CosmosDB, Firebase
            </span>
          </li>
          <li className="item">
            <h2>Languages</h2>
            <span>JavaScript, C#, TypeScript</span>
          </li>
          <li className="item">
            <h2>Cloud Computing</h2>
            <span>Azure</span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;
