import NutriApp from "../assets/NutriApp.png";
import TravelPal from "../assets/TravelPal.png";
import BunDrop from "../assets/BunDrop.png";
import docker_plain from "../assets/docker_plain.png";
import ticket from "../assets/ticket.png";


export const ProjectList = [
  {
    name: "Nutrition App",
    image: NutriApp,
    skills: "React, Node.JS, MongoDB"
  },
  {
    name: "Docker Project",
    image: docker_plain,
    skills: "React, Node.JS, Docker, MongoDB"
  },
  {
    name: "Travel App",
    image: TravelPal,
    skills: "C#, SSMS"
  },
  {
    name: "Hamburger App",
    image: BunDrop,
    skills: "React"
  },
  {
    name: "Event Booking App",
    image: ticket,
    skills: "Blazor Web Assembly, SSMS",
  },
];
