import React from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        <InstagramIcon />
        <TwitterIcon />
        <FacebookIcon />
        <a href="https://linkedin.com/in/mohammed-ali-9b793b165" target="_blank">< LinkedInIcon /></a>

      </div>
      <p> &copy; 2024 mommeali.com</p>
    </div>
  );
}

export default Footer;
